<template>
  <div class="mobile-credential">
    <div class="headback">
      <div class="wrapper">
        <router-link to="/mobile/account" class="cvr-bg-bf">
          Register
        </router-link>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of headback -->
    <div id="login-register" class="holder">
      <div class="wrapper">
        <div class="form-basic">
          <form @submit.prevent="register">
            <div class="gap">
              <div class="fgroup">
                <label>First Name</label>
                <input type="text" v-model="firstName" required />
              </div>
              <!-- end of frgroup -->
              <div class="fgroup">
                <label>Last Name</label>
                <input type="text" v-model="lastName" required />
              </div>
              <!-- end of frgroup -->
            </div>
            <!-- end of gap -->
            <div class="fgroup">
              <label>Email</label>
              <input type="email" v-model="email" required />
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <label>Mobile Number</label>
              <input type="tel" v-model="mobileNumber" required />
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <label>Password</label>
              <div class="pass-field">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  v-model="password"
                  required
                />
                <b></b>
              </div>
              <!-- end of pass field -->
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <div
                class="checky"
                style="text-align: center; color: red; margin-top: 10px"
              >
                <p v-for="(error, index) in errorMessage" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <!-- end of group -->
            <div class="fgroup">
              <div class="checky">
                <label>
                  <input type="checkbox" v-model="termCondition" />
                  <span>
                    By registering, I have read and agreed with
                    <a href="#">Terms and Conditions</a> from Rubii
                  </span>
                </label>
              </div>
            </div>
            <!-- end of group -->
            <div class="fgroup">
              <button type="submit" :disabled="!termCondition" class="button">
                Create My Account
              </button>
            </div>
            <!-- end of group -->
          </form>
        </div>
        <!-- end of form basic -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of holder -->

    <div class="info">
      <div class="wrapper">
        <p>
          Already have an account?
          <router-link to="/mobile/login">Login</router-link>
        </p>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of info -->
  </div>
  <!-- end of mobile register -->
</template>

<script>
import $ from "jquery";

export default {
  name: "Register",
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      mobileNumber: null,
      password: null,
      termCondition: false,
      errorMessage: []
    };
  },
  mounted() {
    $(".form-basic .pass-field b").on("click", function() {
      var self = $(this),
        target = self.closest(".pass-field").find("input");

      if (target.attr("type") == "password") {
        target.attr("type", "text");
        self.addClass("merem");
      } else {
        target.attr("type", "password");
        self.removeClass("merem");
      }
    });
  },
  methods: {
    register() {
      this.$axios
        .post(`/auth/register`, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          mobileNumber: this.mobileNumber,
          password: this.password
        })
        .then(() => {
          this.$swal({
            icon: "success",
            text: "account registration successful"
          });
          this.$router.push(`/auth/login`);
        })
        .catch(error => {
          if (error.response.data.code == 422) {
            this.errorMessage = error.response.data.data.map(
              error => error.message
            );
          }
        });
    }
  }
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  background-color: grey;
  color: #093763;
}
</style>
